.form-control {
  @apply relative block w-full;
}

.form-field-check-box, .form-field-radio-button, .form-field-switch {
  @apply relative flex items-start;

  :where(.form-field-check-box-control, .form-field-radio-button-control, .form-field-switch-control) {
    @apply flex items-center h-5;
  }

  :where(.form-field-check-box-content, .form-field-radio-button-content, .form-field-switch-content) {
    @apply ml-3 text-sm font-medium text-base-700;
  }
}

.form-field-switch-control {
  @apply relative flex-none rounded-full justify-center w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
}

.form-field-advanced-select {
  .form-icon {
    @apply h-[1.3125rem] w-[1.3125rem] mr-2.5;
  }
}

.form-field-editor {
  @apply shadow-none;
}

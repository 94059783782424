.ts-control {
  @apply min-h-[2.625rem] px-3 py-2 text-base leading-5 sm:min-h-[2.375rem];

  & > input {
    @apply w-auto text-base sm:text-sm;
  }
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select,
.form-input,
.form-textarea,
.form-select,
.form-multiselect,
.ts-control {
  @apply bg-base-0 border-base-300 text-base-1000 rounded-md shadow-base-1000 focus:ring-primary focus:border-primary sm:text-sm;

  &.form-invalid {
    @apply border-danger;
  }
}

input, .form-input,
textarea, .form-textarea {
  &::placeholder {
    @apply text-base-500;
  }
}

textarea, .form-textarea {
  @apply min-h-[2.625rem] sm:min-h-[2.375rem];
}

select, .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23a1a1aa' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");

  [data-mode="dark"] & {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%2352525b' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  }
}


[type='checkbox'], .form-checkbox,
[type="radio"], .form-radio {
  @apply h-4 w-4 text-primary bg-base-0 border-base-300 shadow-base-1000 focus:ring-primary [&:not(:checked)]:disabled:bg-base-100 checked:disabled:text-base-300;

  &.form-invalid {
    @apply border-danger;
  }
}

[type="checkbox"], .form-checkbox {
  @apply rounded;

  &:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");

    [data-mode="dark"] & {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    }
  }

  &:indeterminate {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");

    [data-mode="dark"] & {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
    }
  }
}

[type="radio"], .form-radio {
  &:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");

    [data-mode="dark"] & {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    }
  }
}

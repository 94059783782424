.pagy {
  &:where(:not(.info)) {
    @apply relative inline-flex rounded-md shadow-sm -space-x-px;
  }

  a {
    @apply h-full bg-base-0 border-base-300 text-base-500 relative inline-flex items-center px-3 py-2 border text-sm font-medium dark:text-base-600;

    &[href]:not([aria-disabled="true"]) {
      @apply hover:bg-base-50;
    }

    &:not([href]), &[aria-disabled="true"] {
      &:not(.current, :first-child, :last-child) {
        @apply cursor-default text-opacity-50;
      }
    }

    &:first-child, &:last-child {
      @apply px-2.5 text-opacity-0;

      &[aria-disabled="true"] {
        &::before {
          @apply text-opacity-50;
        }
      }

      &::before {
        @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-base-500 fa-solid dark:text-base-600;
      }
    }

    &:first-child {
      @apply rounded-l-md fa-chevron-left;
    }

    &:last-child {
      @apply rounded-r-md fa-chevron-right;
    }

    &.gap {
      @apply px-2;
    }

    &.current {
      @apply bg-secondary text-secondary-content;
    }
  }
}

.ts-wrapper {
  @apply w-full !p-0;

  &.rounded-l-none {
    .ts-control {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  &.rounded-r-none {
    .ts-control {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  &.dropdown-active {
    .ts-control {
      @apply rounded-b-none;
    }
  }

  &.single {
    &.plugin-remove_button {
      &.has-items ~ .form-icon {
        @apply hidden;
      }

      .item .remove {
        @apply flex-shrink-0 ml-0.5 mr-1 h-4 w-4 rounded-full border-0 inline-flex items-center justify-center;
      }
    }

    .ts-control {
      @apply !pr-9;

      input[placeholder=""] {
        @apply min-w-0 h-0 flex-none;
      }
    }
  }

  &.multi {
    &.plugin-clear_button {
      &.has-items {
        .ts-control {
          @apply !pr-9;
        }
      }
    }

    &.plugin-remove_button {
      .item .remove {
        @apply flex-shrink-0 ml-0.5 mr-1 h-4 w-4 rounded-full border-0 inline-flex items-center justify-center text-secondary-content hover:bg-secondary-focus focus:outline-none focus:bg-secondary-focus;
      }
    }

    .ts-control {
      @apply !pr-3;
    }

    .ts-control > div {
      @apply inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-secondary text-secondary-content;

      &.active {
        @apply bg-secondary-focus;
      }
    }

    .ts-control > input {
      @apply text-base-1000;
    }
  }

  &.plugin-dropdown_input {
    .dropdown-input {
      @apply px-3 py-2 border-base-300 text-base text-base-1000 leading-5 focus:ring-primary focus:border-primary sm:text-sm;
    }
  }
}

.full .ts-control, .ts-wrapper.single.input-active .ts-control {
  @apply bg-base-0;
}

.disabled .ts-control {
  @apply opacity-100 bg-base-100;
}

.ts-dropdown {
  @apply mt-0 bg-base-0 border-base-300 rounded-b-md;

  .create, .no-results, .option, .optgroup-header, .dropdown-header {
    @apply px-3 py-1.5;
  }

  .create, .no-results {
    @apply text-base-500;
  }

  .option {
    @apply flex items-center text-base-1000;

    input[type="checkbox"] {
      @apply inline-block h-3.5 w-3.5;
    }
  }

  .active {
    @apply bg-secondary text-base-1000;
  }

  .active.create {
    @apply text-base-1000;
  }

  .optgroup-header, .dropdown-header {
    @apply py-2 bg-base-100 text-xs font-semibold;
  }

  .dropdown-header-close {
    @apply hidden;
  }

  [data-selectable] .highlight {
    @apply bg-base-1000/10;
  }
}

.ts-dropdown-content {
  @apply text-sm leading-5;
}

.form-label {
  @apply text-sm font-medium text-base-700 text-left;

  &:where(legend) {
    @apply w-full;
  }
}

.form-hint {
  @apply mt-1.5 text-[0.8125rem] text-base-500 text-left dark:text-base-600;
}

.form-group {
  @apply col-span-12;

  &.form-group-invalid {
    .form-hint:not(.translation-hint) {
      @apply text-danger;
    }
  }
}

.form-group:where(
  :not(
    .form-group-boolean,
    .form-group-check-boxes,
    .form-group-radio-buttons,
    .form-group-switch
  )
) {
  :where(.form-label) {
    @apply mb-1;
  }

  :where(.form-field) {
    @apply relative flex rounded-md shadow-sm;

    :where(.form-field-addon) {
      @apply shadow-none;

      &:first-child {
        .ts-control {
          @apply !border-r-0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &:last-child {
        .ts-control {
          @apply !border-l-0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    :where(.form-addon) {
      @apply flex-none border first:rounded-l-md first:border-r-0 last:rounded-r-md last:border-l-0 focus:z-[1];

      &.btn {
        @apply px-3;
      }

      &:not(.btn) {
        @apply inline-flex items-center px-3 bg-base-50 border-base-300 text-base-500 sm:text-sm;
      }
    }

    :where(.form-control) {
      @apply flex-1;

      :where([class*="fa-"]:not(.form-icon-content), .form-icon), .ts-control .clear-button, .ts-wrapper.single .ts-control .remove {
        @apply z-[2] absolute top-1/2 -translate-y-1/2 flex items-center text-base text-base-400 text-center pointer-events-none first:left-0 first:ml-3 last:right-0 last:mr-3;
      }

      .ts-control .clear-button, .ts-wrapper.single .ts-control .remove {
        @apply pointer-events-auto w-4 !px-0 text-center first:left-auto first:ml-auto last:!mr-3;

        .form-icon-content {
          @apply mx-auto;
        }
      }

      :where(.form-icon) {
        @apply w-4 focus:z-[1];
      }
    }

    :where(input, textarea, select, .form-input, .form-textarea, .form-select, .form-multiselect) {
      @apply block w-full text-left focus:z-[1];
    }
  }
}

.form-group-editor {
  :where(.form-label) {
    @apply mb-2.5;
  }
}

.form-group-date-select,
.form-group-datetime-select,
.form-group-time-select {
  :where(.form-field) {
    @apply shadow-none;

    .form-control {
      @apply flex flex-wrap gap-2;
    }

    :where(select) {
      @apply w-auto max-w-full shadow-sm;
    }
  }
}

.form-group-radio-buttons,
.form-group-check-boxes {
  :where(.form-label) {
    @apply mb-3;
  }
}

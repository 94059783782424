.btn {
  @apply inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm text-center font-medium rounded shadow-sm transition focus:outline-none focus:ring-primary focus:ring-2 focus:ring-offset-2 focus:ring-offset-base-0;

  :where([class*="fa-"]:not(.btn-icon-content), .btn-icon) {
    @apply inline-flex justify-center items-center h-5 w-5;

    &:first-child {
      @apply -ml-1 mr-2;
    }

    &:last-child {
      @apply ml-2 -mr-1;
    }

    &:first-child:last-child {
      @apply -mx-1;
    }
  }
}

.btn-xs {
  @apply px-2.5 py-1.5 text-xs;

  :where([class*="fa-"]:not(.btn-icon-content), .btn-icon) {
    @apply h-4 w-4;

    &:first-child {
      @apply -ml-0.5 mr-2;
    }

    &:last-child {
      @apply ml-2 -mr-0.5;
    }

    &:first-child:last-child {
      @apply -mx-0.5;
    }
  }
}

.btn-sm {
  @apply px-3 py-2 text-sm leading-4;

  :where([class*="fa-"]:not(.btn-icon-content), .btn-icon) {
    @apply h-4 w-4;

    &:first-child {
      @apply -ml-0.5 mr-2;
    }

    &:last-child {
      @apply ml-2 -mr-0.5;
    }

    &:first-child:last-child {
      @apply -mx-1;
    }
  }
}

.btn-lg {
  @apply px-4 py-2 text-base;

  :where([class*="fa-"]:not(.btn-icon-content), .btn-icon) {
    @apply h-5 w-5;

    &:first-child {
      @apply -ml-1 mr-3;
    }

    &:last-child {
      @apply ml-3 -mr-1;
    }

    &:first-child:last-child {
      @apply -mx-1;
    }
  }
}

.btn-xl {
  @apply px-6 py-3 text-base;

  :where([class*="fa-"]:not(.btn-icon-content), .btn-icon) {
    @apply h-5 w-5;

    &:first-child {
      @apply -ml-1 mr-3;
    }

    &:last-child {
      @apply ml-3 -mr-1;
    }

    &:first-child:last-child {
      @apply -mx-1;
    }
  }
}

.btn-rounded {
  @apply rounded-full;
}

.btn-circle {
  @apply p-2 rounded-full;

  &.btn-xs {
    @apply p-1;
  }

  &.btn-sm {
    @apply p-1.5;
  }

  &.btn-lg {
    @apply p-2;
  }

  &.btn-xl {
    @apply p-3;
  }
}

.btn-primary {
  @apply bg-primary text-primary-content;

  &:hover {
    &:not(.btn-disabled, .btn:disabled, .btn[disabled]) {
      @apply bg-primary-focus;
    }
  }
}

.btn-secondary {
  @apply bg-secondary text-secondary-content;

  &:hover {
    &:not(.btn-disabled, .btn:disabled, .btn[disabled]) {
      @apply bg-secondary-focus;
    }
  }
}

.btn-danger {
  @apply bg-danger text-danger-content;

  &:hover {
    &:not(.btn-disabled, .btn:disabled, .btn[disabled]) {
      @apply bg-danger-focus;
    }
  }
}

.btn-success {
  @apply bg-success text-success-content;

  &:hover {
    &:not(.btn-disabled, .btn:disabled, .btn[disabled]) {
      @apply bg-success-focus;
    }
  }
}

.btn-base-50 {
  @apply bg-base-50 border-base-300 text-base-500 dark:text-base-600;

  &:hover {
    &:not(.btn-disabled, .btn:disabled, .btn[disabled]) {
      @apply bg-base-100;
    }
  }
}

.btn-outline {
  @apply bg-transparent;

  &.btn-primary {
    @apply border-primary text-primary;

    &:hover {
      &:not(.btn-disabled, .btn:disabled, .btn[disabled]) {
        @apply bg-primary-focus text-primary-content;
      }
    }
  }

  &.btn-secondary {
    @apply border-secondary text-secondary;

    &:hover {
      &:not(.btn-disabled, .btn:disabled, .btn[disabled]) {
        @apply bg-secondary-focus text-secondary-content;
      }
    }
  }

  &.btn-danger {
    @apply border-danger text-danger;

    &:hover {
      &:not(.btn-disabled, .btn:disabled, .btn[disabled]) {
        @apply bg-danger-focus text-danger-content;
      }
    }
  }

  &.btn-success {
    @apply border-success text-success;

    &:hover {
      &:not(.btn-disabled, .btn:disabled, .btn[disabled]) {
        @apply bg-success-focus text-success-content;
      }
    }
  }
}

.btn-disabled, .btn:disabled, .btn[disabled] {
  &, &:hover {
    @apply cursor-not-allowed bg-opacity-70 text-opacity-50;
  }
}

.btn-dropdown-trigger {
  &.btn {
    @apply px-2.5;
  }

  &.btn-xs {
    @apply px-2;
  }

  &.btn-lg {
    @apply px-3.5;
  }

  &.btn-xl {
    @apply px-4;
  }
}

.btn-group {
  & > .btn {
    &:not(:first-child) {
      @apply rounded-l-none;

      &.btn-primary {
        @apply border-l-primary-focus;
      }

      &.btn-secondary {
        @apply border-l-secondary-focus;
      }

      &.btn-danger {
        @apply border-l-danger-focus;
      }

      &.btn-success {
        @apply border-l-success-focus;
      }
    }

    &:not(:last-child) {
      @apply rounded-r-none border-r-0;
    }
  }
}

.flatpickr-months {
  .flatpickr-prev-month, .flatpickr-next-month {
    &:hover {
      @apply text-primary;

      svg {
        @apply fill-primary;
      }
    }
  }
}

.flatpickr-current-month span.cur-month {
  @apply font-medium hover:bg-transparent;
}

span.flatpickr-day {
  &.today, &.prevMonthDay.today, &.nextMonthDay.today {
    &:not(.selected) {
      @apply border-[#e9e9e9] border-b-primary;

      &:hover {
        @apply border-secondary border-b-primary;
      }
    }
  }
}

.flatpickr-day {
  &.inRange, &.prevMonthDay.inRange, &.nextMonthDay.inRange, &.today.inRange, &.prevMonthDay.today.inRange, &.nextMonthDay.today.inRange, &:hover, &.prevMonthDay:hover, &.nextMonthDay:hover, &:focus, &.prevMonthDay:focus, &.nextMonthDay:focus {
    @apply bg-base-100 border-base-100 dark:bg-base-900 dark:border-base-900;
  }

  &.today {
    &:hover, &:focus {
      @apply bg-secondary border-secondary text-secondary-content;
    }
  }

  &.selected, &.startRange, &.endRange {
    &, &.inRange, &:focus, &:hover, &.prevMonthDay, &.nextMonthDay {
      @apply bg-primary border-primary text-primary-content;
    }
  }
}

.flatpickr-time {
  input, .flatpickr-am-pm {
    &:hover, &:focus {
      @apply bg-base-100 dark:bg-base-900;
    }
  }
}

.section-head {
  @apply pb-5 border-b border-base-200 sm:flex sm:items-center sm:justify-between;
}

.section-head-actions {
  @apply flex mt-3 gap-3 sm:mt-0 sm:ml-4;
}

.section-title {
  @apply text-lg leading-6 font-medium text-base-900;
}

.section-description {
  @apply mt-2 max-w-4xl text-sm text-base-500 first:mt-0;
}

.form-content-locale-select {
  .form-field-advanced-select {
    .ts-wrapper.single {
      .ts-control {
        @apply !pr-8 text-base;
      }
    }

    .ts-wrapper.dropdown-active {
      .ts-control {
        @apply rounded-md;
      }
    }

    .ts-control {
      @apply text-base md:!leading-5;
    }

    .ts-dropdown {
      @apply left-auto right-0 w-auto mt-1 rounded-md;

      .dropdown-header-title {
        @apply whitespace-nowrap;
      }

      .option {
        .flag {
          @apply text-base leading-5;
        }
      }
    }

    .form-icon {
      @apply mr-2;
    }
  }
}

/* Entry point for your PostCSS build */
@import 'flatpickr/dist/flatpickr';
@import 'flatpickr/dist/themes/airbnb';
@import 'highlight.js/styles/github-dark';
@import 'tom-select/dist/css/tom-select';

@import 'tailwindcss/base';
@import './fonts/inter';
@import './fonts/fontawesome';
@import './base';

@import 'tailwindcss/components';

@import './components/form/base';
@import './components/form/layout';
@import './components/form/multilingual';
@import './components/form/group';
@import './components/form/field';
@import './components/form/editor';
@import './components/form/flatpickr';
@import './components/form/tom-select';

@import './components/badge';
@import './components/button';
@import './components/card';
@import './components/dropdown';
@import './components/pagination';
@import './components/section';
@import './components/table';

@import 'tailwindcss/utilities';

@config '../../../tailwind/config.js';

:root {
  --page-sticky-top: 6.6875rem;

  @media screen(md) {
    --page-sticky-top: 3.9375rem;
  }
}

* {
  @apply outline-primary;
}

mark {
  @apply bg-secondary text-secondary-content;
}



.card {
  @apply bg-base-0 shadow rounded-lg;
}

.card-head {
  @apply px-4 py-5 border-b border-base-200 first:rounded-t-lg last:rounded-b-lg dark:border-base-300 sm:px-6;
}

.card-head-groups {
  @apply flex justify-between items-center gap-4 flex-wrap sm:flex-nowrap;
}

.card-title {
  @apply text-lg leading-6 font-medium text-base-900;
}

.card-table {
  @apply overflow-x-auto border-base-300 border-y first:rounded-t-lg first:border-t-0 last:rounded-b-lg last:border-b-0;
}

.card-description {
  @apply mt-1 text-sm text-base-500 first:mt-0 dark:text-base-600;
}

.card-body {
  @apply px-4 py-5 first:rounded-t-lg last:rounded-b-lg sm:px-6;
}

.card-foot {
  @apply bg-base-50 px-4 py-4 first:rounded-t-lg last:rounded-b-lg sm:px-6 dark:bg-base-0 dark:border-t dark:border-base-300;
}

.arx-editor-container {
  @apply shadow-sm;
}

.arx-bars-sticky {
  @apply !top-[6.5rem] md:!top-16;
}

.arx-pathbar-container {
  @apply !bg-zinc-100;
}

.arx-toolbar-container {
  @apply !bg-zinc-50;
}

.arx-popup-images-box {
  @apply grid grid-cols-[repeat(auto-fill,_minmax(5rem,_1fr))] gap-2 sm:gap-3 sm:grid-cols-[repeat(auto-fill,_minmax(7.5rem,_1fr))] md:gap-4 md:!mt-4;
}

.arx-popup-images-box img {
  @apply w-full !max-w-full aspect-[1/1] object-cover !m-0;
}

.editor-light {
  .arx-editor-container, .arx-editor-frame {
    @apply rounded-md;
  }

  .arx-bars-container {
    @apply absolute !top-0 right-0 -translate-y-full w-max max-w-full;
  }
}

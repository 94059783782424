.table {
  @apply min-w-full text-left;

  :where(thead, tfoot) {
    @apply bg-base-50 dark:bg-base-0;
  }

  :where(thead) {
    @apply relative z-[1] before:absolute before:bottom-0 before:left-0 before:h-px before:w-full before:bg-base-300;
  }

  :where(tbody) {
    @apply divide-y divide-base-200 bg-base-0;
  }

  :where(tfoot) {
    @apply relative before:absolute before:top-0 before:left-0 before:h-px before:w-full before:bg-base-300;
  }

  :where(th, td) {
    @apply px-3 text-sm;

    &:first-child {
      @apply pl-4 sm:pl-6;
    }

    &:last-child {
      @apply pr-4 sm:pr-6;
    }
  }

  :where(thead th) {
    @apply py-3.5;
  }

  :where(tbody th, tbody td) {
    @apply h-14 py-2.5 bg-base-0 text-base-500 dark:text-base-600;
  }

  :where(tfoot th, tfoot td) {
    @apply py-3.5;
  }

  :where(tbody th[scope="colgroup"]) {
    @apply bg-base-100 py-2;
  }

  :where(thead th, tbody th[scope="colgroup"]) {
    @apply font-semibold text-base-900;
  }

  .sortable-ghost {
    @apply opacity-20;
  }
}

.sort_link {
  @apply relative block overflow-hidden text-ellipsis pr-4 fa-sort;

  &::before {
    @apply absolute top-1/2 -translate-y-1/2 right-0 fa-solid;
  }

  &.asc {
    @apply fa-sort-up;
  }

  &.desc {
    @apply fa-sort-down;
  }
}

.badge {
  @apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium;

  &.badge-rounded {
    @apply px-2 rounded;
  }
}

.badge-lg {
  @apply px-3 text-sm;

  &.badge-rounded {
    @apply px-2.5 rounded-md;
  }
}

.badge-icon {
  @apply inline-flex items-center justify-center h-4 w-4 rounded-full mx-0.5 first:ml-0 last:mr-0;
}

.badge-primary {
  @apply bg-primary text-primary-content;
}

.badge-secondary {
  @apply bg-secondary text-secondary-content;
}

.badge-danger {
  @apply bg-danger text-danger-content;
}

.badge-success {
  @apply bg-success text-success-content;
}

.dropdown-wrapper {
  @apply inline-flex;
}

.dropdown-content {
  @apply z-[99] absolute top-0 left-0 will-change-transform;
}

.dropdown-menu {
  @apply w-56 min-w-full py-1 rounded-md shadow-lg bg-base-0 ring-1 ring-base-1000 ring-opacity-5 dark:bg-base-100;
}

.dropdown-group {
  @apply py-1;
}

.dropdown-action {
  @apply flex items-center w-full px-4 py-2 text-base-700 text-sm text-left hover:bg-base-100 hover:text-base-900 dark:hover:bg-base-200;

  :where([class*="fa-"]:not(.dropdown-action-icon-content), .dropdown-action-icon) {
    @apply inline-flex flex-none justify-center items-center h-5 w-5 text-base-400;

    &:first-child {
      @apply -ml-1 mr-2;
    }

    &:last-child {
      @apply ml-2 -mr-1;
    }
  }

  &:hover {
    :where([class*="fa-"]:not(.dropdown-action-icon-content), .dropdown-action-icon) {
      @apply text-base-500;
    }
  }
}

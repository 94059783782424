.form-list {
  @apply space-y-7;

  & > .form-grid:not([hidden]) ~ .form-grid:not([hidden]), & > .form-actions {
    @apply border-t border-base-200 pt-7;
  }
}

.form-grid {
  @apply grid grid-cols-12 gap-6;

  & + .form-grid, & + .form-actions {
    @apply pt-8;
  }

  :where(.form-grid) {
    @apply col-span-12;

    & + .form-grid {
      @apply pt-0;
    }
  }
}

.form-actions {
  @apply flex flex-col gap-3 sm:flex-row sm:justify-end;
}

.form-head {
  @apply col-span-12;
}

.form-title {
  @apply text-lg leading-6 font-medium text-base-900;
}
